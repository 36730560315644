import "./App.css";
import LogIn from "./pages/LogIn/LogIn";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./pages/ProtectedRoute/ProtectedRoute";
import Dashboards from "./pages/Dashboard/Dashboards";
import Layout from "./Layout/Layout";
import AddOrEdit from "./pages/AddOrEdit/AddOrEditFormik";
import PromoItems from "./pages/PromoItems/PromoItems";
import CasinoItems from "./pages/CasinoItems/CasinoItems";
import GamesItems from "./pages/GamesItems/GamesItems";
import AddOrEditCazino from "./pages/AddOrEditCazino/AddOrEditCazino";
import AddOrEditGame from "./pages/AddOrEditGame/AddOrEditGame";
import { Heading } from "@chakra-ui/react";
import GamesAccordion from "./pages/GamesItems/GamesAccordion/GamesAccordion";
import Utilizatori from "./pages/Utilizatori/Utilizatori";
import JibberishWork from "./components/JibberishWork/JibberishWork";
import AddOrEditGameMeta from "./pages/AddOrEditGameMeta/AddOrEditGameMeta";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LogIn />} />
          <Route
            path="admin-dashboard"
            element={
              <ProtectedRoute>
                <Dashboards />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="promo-items"
            element={
              <ProtectedRoute>
                <PromoItems />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="add-promo-item"
            element={
              <ProtectedRoute>
                <AddOrEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit-promo-item"
            element={
              <ProtectedRoute>
                <AddOrEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="casino-items"
            element={
              <ProtectedRoute>
                <CasinoItems />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="add-casino-item"
            element={
              <ProtectedRoute>
                <AddOrEditCazino />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit-casino-item"
            element={
              <ProtectedRoute>
                <AddOrEditCazino />
              </ProtectedRoute>
            }
          />
          <Route
            path="games-items"
            element={
              <ProtectedRoute>
                <GamesItems />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="games-items/:category"
            element={
              <ProtectedRoute>
                <GamesAccordion />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="add-game-item"
            element={
              <ProtectedRoute>
                <AddOrEditGame />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit-game-item"
            element={
              <ProtectedRoute>
                <AddOrEditGame />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="edit-game-category-meta"
            element={
              <ProtectedRoute>
                <AddOrEditGameMeta />
              </ProtectedRoute>
            }
          />
          <Route
            path="utilizatori"
            element={
              <ProtectedRoute>
                <Utilizatori />
              </ProtectedRoute>
            }
          />
          <Route
            path="utilizatori/:id"
            element={
              <ProtectedRoute>
                <Utilizatori />
              </ProtectedRoute>
            }
          />
          <Route
            path="jibberish-work"
            element={
              <ProtectedRoute>
                <JibberishWork />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
