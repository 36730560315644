import axios from "axios";

class PromoCarouselDataService {
  addPromoCarousel = (newPromoCarousel, id) => {
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/addPromoCarousel",
      {
        id: id,
        newPromoCarousel: newPromoCarousel,
      },
      { withCredentials: false }
    );
  };

  updatePromoCarousel = (id, updatedPromoCarousel) => {
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/updatePromoCarousel",
      {
        id: id,
        updatedPromoCarousel: updatedPromoCarousel,
      },
      { withCredentials: false }
    );
  };

  deletePromoCarousel = (id) => {
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/deletePromoCarousel",
      {
        id: id,
      },
      { withCredentials: false }
    );
  };

  getAllPromoCarousels = () => {
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/getAllPromoCarousels",
      {
        withCredentials: false,
      }
    );
  };

  getPromoCarousel = (id) => {};
}

export default new PromoCarouselDataService();
