import React, { useState } from 'react';
import PromoCarouselDataService from '../../services/promo-carousel-services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearState,
  updateState,
} from '../../features/promoItems/addPromoItemSlice';
import {
  addPromoCarouselItem,
  editPromoCarouselItem,
} from '../../features/promoItems/rememberPromoCarouselItemsSlice';
import { Formik, Form, Field } from 'formik';

import {
  FormControl,
  FormLabel,
  Input,
  Heading,
  Container,
  Box,
  Switch,
  Textarea,
  Spacer,
  Button,
  Center,
  VStack,
  FormErrorMessage,
} from '@chakra-ui/react';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import AddOrEditSchema from './AddorEditValidationSchema';

function AddOrEdit(props) {
  const dispatch = useDispatch();

  const add = useSelector(state => state.addPromoItem.add);
  const id = useSelector(state => state.addPromoItem.id);
  const name = useSelector(state => state.addPromoItem.name);
  const title1 = useSelector(state => state.addPromoItem.title1);
  const title2 = useSelector(state => state.addPromoItem.title2);
  const text1 = useSelector(state => state.addPromoItem.text1);
  const text2 = useSelector(state => state.addPromoItem.text2);
  const imageURL = useSelector(state => state.addPromoItem.imageURL);
  const webURL = useSelector(state => state.addPromoItem.webURL);
  const buttonText = useSelector(state => state.addPromoItem.buttonText);
  const isPublished = useSelector(state => state.addPromoItem.isPublished);
  const isIOSPublished = useSelector(
    state => state.addPromoItem.isIOSPublished
  );
  const priority = useSelector(state => state.addPromoItem.priority);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const submitForm = async values => {
    setIsLoading(true);
    const titlesArray =
      title2 !== ''
        ? [values.title1.trim(), values.title2.trim()]
        : [values.title1.trim()];
    const textsArray =
      text2 !== ''
        ? [values.text1.trim(), values.text2.trim()]
        : [values.text1.trim()];

    const newPromoItem = {
      name: values.name.trim().toUpperCase(),
      titlesArray: titlesArray,
      textsArray: textsArray,
      imageURL: values.imageURL.trim(),
      webURL: values.webURL.trim(),
      isPublished: values.isPublished,
      isIOSPublished: values.isIOSPublished,
      priority: parseInt(values.priority),
      buttonText: values.buttonText.trim(),
    };
    console.log(id.value);
    try {
      console.log(newPromoItem)
      await PromoCarouselDataService.addPromoCarousel(newPromoItem, id.value);
      dispatch(clearState());
      if (add === true)
        dispatch(addPromoCarouselItem({ newItem: newPromoItem, id: id.value }));
      else
        dispatch(
          editPromoCarouselItem({ editedItem: newPromoItem, id: id.value })
        );
      toast.success('Operatiunea s-a realizat cu succes!');
      setIsLoading(false);
      navigate('/promo-items');
    } catch (error) {
      console.log(error);
      toast.error('S-a produs o eraore. Incearca din nou.');
      setIsLoading(false);
    }
  };

  return (
    <Container mt="3rem" maxW="container.lg">
      <VStack>
        <Link to="/promo-items">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
            onClick={() => {
              dispatch(clearState());
            }}
          >
            Back
          </Button>
        </Link>
        <Spacer />
        <Heading textAlign={'center'} size="xl" py="3" mb="5">
          {add ? 'Adauga un item in carusel' : 'Editeaza item-ul din carusel'}
        </Heading>
      </VStack>

      <Box
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="sm"
        my="2rem"
        p="2rem"
        maxW={'900px'}
      >
        <Box p={8}>
          <Formik
            initialValues={{
              id: id.value,
              name: name.value,
              title1: title1.value,
              text1: text1.value,
              title2: title2.value,
              text2: text2.value,
              imageURL: imageURL.value,
              webURL: webURL.value,
              buttonText: buttonText.value,
              priority: priority.value,
              isIOSPublished: isIOSPublished.value,
              isPublished: isPublished.value,
            }}
            onSubmit={values => {
              console.log(values);
              let showToast = false;
              if (
                (values.text1 !== '' && values.title1 === '') ||
                (values.text1 === '' && values.title1 !== '')
              ) {
                showToast = true;
              }

              if (
                (values.text2 !== '' && values.title2 === '') ||
                (values.text2 === '' && values.title2 !== '')
              ) {
                showToast = true;
              }
              if (showToast)
                toast.error(
                  'Titlul si textul corespunzator trebuie mereu sa existe'
                );
              else {
                submitForm(values);
              }
            }}
            validationSchema={AddOrEditSchema}
          >
            {({ values, errors, touched, dirty, isValid, setFieldValue }) => (
              <Form>
                <FormControl
                  isInvalid={errors.id && touched.id}
                  mb={8}
                  variant="floating"
                >
                  <Field
                    as={Input}
                    name="id"
                    isDisabled={add ? false : true}
                    value={values.id}
                    onChange={e => {
                      dispatch(
                        updateState({
                          value: e.target.value,
                          name: e.target.name,
                        })
                      );
                      setFieldValue('id', e.target.value);
                    }}
                    placeholder=" "
                  />
                  <FormLabel>ID promoție</FormLabel>
                  {errors.id && touched.id ? (
                    <FormErrorMessage>{errors.id}</FormErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl
                  isInvalid={errors.name && touched.name}
                  mb={8}
                  variant="floating"
                  isRequired
                >
                  <Field
                    as={Input}
                    name="name"
                    value={values.name}
                    onChange={e => {
                      dispatch(
                        updateState({
                          value: e.target.value,
                          name: e.target.name,
                        })
                      );
                      setFieldValue('name', e.target.value);
                    }}
                    placeholder=" "
                  />

                  <FormLabel>Nume promoție</FormLabel>
                  {errors.name && touched.name ? (
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl
                  isInvalid={errors.title1 && touched.title1}
                  mb={8}
                  variant="floating"
                  isRequired
                >
                  <Field
                    as={Input}
                    name="title1"
                    value={values.title1}
                    onChange={e => {
                      dispatch(
                        updateState({
                          value: e.target.value,
                          name: e.target.name,
                        })
                      );
                      setFieldValue('title1', e.target.value);
                    }}
                    placeholder=" "
                  />

                  <FormLabel>Titlu 1</FormLabel>
                  {errors.title1 && touched.title1 ? (
                    <FormErrorMessage>{errors.title1}</FormErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={errors.text1 && touched.text1}
                  mb={8}
                  variant="floating"
                >
                  <Textarea
                    name="text1"
                    value={values.text1}
                    onChange={e => {
                      dispatch(
                        updateState({
                          value: e.target.value,
                          name: e.target.name,
                        })
                      );
                      setFieldValue('text1', e.target.value);
                    }}
                    placeholder=" "
                  />

                  <FormLabel>Text 1</FormLabel>
                  {errors.text1 && touched.title1 ? (
                    <FormErrorMessage>{errors.text1}</FormErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl
                  isInvalid={errors.title2 && touched.title2}
                  mb={8}
                  variant="floating"
                >
                  <Field
                    as={Input}
                    name="title2"
                    value={values.title2}
                    onChange={e => {
                      dispatch(
                        updateState({
                          value: e.target.value,
                          name: e.target.name,
                        })
                      );
                      setFieldValue('title2', e.target.value);
                    }}
                    placeholder=" "
                  />

                  <FormLabel>Titlu 2</FormLabel>
                  {errors.title2 && touched.title2 ? (
                    <FormErrorMessage>{errors.title2}</FormErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl
                  isInvalid={errors.text2 && touched.text2}
                  mb={8}
                  variant="floating"
                >
                  <Textarea
                    name="text2"
                    value={values.text2}
                    onChange={e => {
                      dispatch(
                        updateState({
                          value: e.target.value,
                          name: e.target.name,
                        })
                      );
                      setFieldValue('text2', e.target.value);
                    }}
                    placeholder=" "
                  />

                  <FormLabel>Text 2</FormLabel>
                  {errors.text2 && touched.text2 ? (
                    <FormErrorMessage>{errors.text2}</FormErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={errors.imageURL && touched.imageURL}
                  mb={8}
                  variant="floating"
                >
                  <Field
                    as={Input}
                    name="imageURL"
                    value={values.imageURL}
                    onChange={e => {
                      dispatch(
                        updateState({
                          value: e.target.value,
                          name: e.target.name,
                        })
                      );
                      setFieldValue('imageURL', e.target.value);
                    }}
                    placeholder=" "
                  />

                  <FormLabel>URL imagine</FormLabel>
                  {errors.imageURL && touched.imageURL ? (
                    <FormErrorMessage>{errors.imageURL}</FormErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl
                  isInvalid={errors.webURL && touched.webURL}
                  mb={8}
                  variant="floating"
                  isRequired
                >
                  <Field
                    as={Input}
                    name="webURL"
                    value={values.webURL}
                    onChange={e => {
                      dispatch(
                        updateState({
                          value: e.target.value,
                          name: e.target.name,
                        })
                      );
                      setFieldValue('webURL', e.target.value);
                    }}
                    placeholder=" "
                  />
                  <FormLabel>URL pagina web</FormLabel>
                  {errors.webURL && touched.webURL ? (
                    <FormErrorMessage>{errors.webURL}</FormErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl
                  isInvalid={errors.buttonText && touched.buttonText}
                  mb={8}
                  variant="floating"
                  isRequired
                >
                  <Field
                    as={Input}
                    name="buttonText"
                    value={values.buttonText}
                    onChange={e => {
                      dispatch(
                        updateState({
                          value: e.target.value,
                          name: e.target.name,
                        })
                      );
                      setFieldValue('buttonText', e.target.value);
                    }}
                    placeholder=" "
                  />

                  <FormLabel>Text buton</FormLabel>
                  {errors.buttonText && touched.buttonText ? (
                    <FormErrorMessage>{errors.buttonText}</FormErrorMessage>
                  ) : null}
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={errors.priority && touched.priority}
                  mb={8}
                  variant="floating"
                >
                  <Field
                    name="priority"
                    as={Input}
                    value={values.priority}
                    onChange={e => {
                      dispatch(
                        updateState({
                          value: e.target.value,
                          name: e.target.name,
                        })
                      );
                      setFieldValue('priority', e.target.value);
                    }}
                    placeholder=" "
                  />

                  <FormLabel>Prioritate</FormLabel>
                  {errors.priority && touched.priority ? (
                    <FormErrorMessage>{errors.priority}</FormErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl mb={8} display="flex" alignItems="center">
                  <FormLabel htmlFor="public-android" mb="0">
                    Este public pe Android?{' '}
                    {values.isPublished ? ' (DA)' : ' (NU)'}
                  </FormLabel>
                  <Field
                    type="checkbox"
                    name="isPublished"
                    as={Switch}
                    id="public-android"
                  />

                  <Spacer />
                  <FormLabel htmlFor="public-ios" mb="0">
                    Este public pe iOs?{' '}
                    {values.isIOSPublished ? ' (DA)' : ' (NU)'}
                  </FormLabel>
                  <Field
                    type="checkbox"
                    name="isIOSPublished"
                    as={Switch}
                    id="public-ios"
                  />
                  <Spacer />
                </FormControl>
                <Center mt={'4rem'}>
                  <Button
                    isDisabled={!(dirty && isValid)}
                    isLoading={isLoading}
                    minW={'200px'}
                    loadingText="Se actualizeaza"
                    type="submit"
                    colorScheme="green"
                    width="full"
                    textAlign="center"
                  >
                    {add ? 'Adauga' : 'Editeaza'}
                  </Button>
                </Center>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <br />
    </Container>
  );
}

export default AddOrEdit;
