import React from "react";
import { useEffect, useState } from "react";
import CasinosDataService from "../../services/casinos-services";
import { useSelector, useDispatch } from "react-redux";
import { setCazinoItems } from "../../features/casionoItems/rememberCasinoItems";
import { clearState } from "../../features/casionoItems/addOrEditCasinoItemsSlice";

import {
  Heading,
  Container,
  Box,
  HStack,
  Spacer,
  SimpleGrid,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { IconContext } from "react-icons";
import { DiAndroid, DiApple } from "react-icons/di";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import CasinoAccordion from "./CasinoAccordion/CasinoAccordion";

function CasinoItems(props) {
  const dispatch = useDispatch();

  const noDeposit = useSelector((state) => state.rememberCasinoItems.noDeposit);
  const topCazinos = useSelector(
    (state) => state.rememberCasinoItems.topCazinos
  );

  const getCasinoItems = async () => {
    console.log("Getting items...");
    try {
      const tmp = await CasinosDataService.getAllCasinos();

      for (let x in tmp.data) {
        dispatch(
          setCazinoItems({
            id: tmp.data[x].id,
            cazinos: tmp.data[x].cazinos,
            isAndroidPublished: tmp.data[x].isAndroidPublished,
            isIOSPublished: tmp.data[x].isIOSPublished,
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // TODO:
    // [-] 1. fix get title and text 2

    if (noDeposit.cazinos.length === 0 && topCazinos.cazinos.length === 0) {
      getCasinoItems();
    } else {
      console.log("Restoring from store");
    }
  }, []);

  return (
    <Container mt="3rem" minWidth="95%">
      <Heading size="xl">Cazinos Dashboard</Heading>
      <HStack mt="3">
        <Link to="/admin-dashboard">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
          >
            Dashboard
          </Button>
        </Link>
        <Spacer />
        <Link to="/add-casino-item">
          <Button
            onClick={() => {
              dispatch(clearState());
            }}
            colorScheme={"green"}
          >
            Adauga Cazino
          </Button>
        </Link>
      </HStack>

      <Accordion mt="10" allowToggle>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <HStack>
                <IconContext.Provider
                  value={{
                    color: topCazinos.isAndroidPublished ? "green" : "black",
                  }}
                >
                  <DiAndroid size={"20"} />
                </IconContext.Provider>
                <IconContext.Provider
                  value={{
                    color: topCazinos.isIOSPublished ? "green" : "black",
                  }}
                >
                  <DiApple size={"20"} />
                </IconContext.Provider>
                <Box ml={"20px"}></Box>
                <Heading size="lg">Cazinouri Top</Heading>
              </HStack>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <CasinoAccordion
              whichIs="topCazinos"
              cazinos={topCazinos.cazinos}
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <HStack>
                <IconContext.Provider
                  value={{
                    color: noDeposit.isAndroidPublished ? "green" : "black",
                  }}
                >
                  <DiAndroid size={"20"} />
                </IconContext.Provider>
                <IconContext.Provider
                  value={{
                    color: noDeposit.isIOSPublished ? "green" : "black",
                  }}
                >
                  <DiApple size={"20"} />
                </IconContext.Provider>
                <Box ml={"20px"}></Box>
                <Heading size="lg">Cazinouri Fără Depozit</Heading>
              </HStack>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <CasinoAccordion whichIs="noDeposit" cazinos={noDeposit.cazinos} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Container>
  );
}

export default CasinoItems;
