import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  casinoOffers: {
    title: '...',
    id: 'casinoOffers',
    products: [],
  },
  paysafeCards: {
    title: '...',
    id: 'paysafeCards',
    products: [],
  },
};

const rememberStoreItemsSlice = createSlice({
  name: 'StoreItems',
  initialState,
  reducers: {
    setStoreItems: (state, { payload }) => {
      console.log(payload);
      state['casinoOffers'] = payload['casinoOffers'];
      state['paysafeCards'] = payload['paysafeCards'];
    },

    removeItemFromShop: (state, { payload }) => {
      console.log(payload.id, parseInt(payload.index));
      state[payload.id].products.splice(parseInt(payload.index), 1);
    },
    addShopItem: (state, { payload }) => {
      state[payload.id].products.push(payload.product);
    },
    editShopItem: (state, { payload }) => {
      for (let shopElement in state[payload.id].products[payload.index]) {
        state[payload.id].products[payload.index][shopElement] =
          payload['product'][shopElement];
      }
    },
  },
});

export default rememberStoreItemsSlice.reducer;
export const { setStoreItems, removeItemFromShop, addShopItem, editShopItem } =
  rememberStoreItemsSlice.actions;
