import axios from "axios";

class CasinosDataService {
  getAllCasinos = () => {
    return axios.post("http://pacanele-gratis-api.alevla.com/getAllCasinos", {
      withCredentials: false,
    });
  };

  addCazino = (newCasino, id) => {
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/addCazino",
      {
        newCasino: newCasino,
        id: id,
      },
      { withCredentials: false }
    );
  };

  deleteCazino = (toDeleteCasino, id) => {
    console.log(toDeleteCasino, id);
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/deleteCazino",
      {
        toDeleteCasino: toDeleteCasino,
        id: id,
      },
      { withCredentials: false }
    );
  };

  editCazino = (toEditCazinoItem, id, index, arrayToUpdate) => {
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/editCazino",
      {
        toEditCazinoItem: toEditCazinoItem,
        id: id,
        index: index,
        arrayToUpdate: arrayToUpdate,
      },
      { withCredentials: false }
    );
  };
}

export default new CasinosDataService();
