import axios from "axios";

class UsersDataService {
  getAllUsers = () => {
    return axios.post("http://pacanele-gratis-api.alevla.com/getAllUsers", {
      withCredentials: false,
    });
  };

  // modifyAllUsersCoinCount = async () => {
  //   try {
  //     let userDocs = await getDocs(query(usersCollectionRef));
  //     for (let document of userDocs.docs) {
  //       const id = document.id;

  //       let newDocValues = {
  //         ...document.data(),
  //         coinCount: document.data().coinCount
  //           ? parseInt(document.data().coinCount) + 100
  //           : 100,
  //       };
  //       const docRef = doc(db, 'users', id);
  //       await setDoc(docRef, newDocValues, { merge: true });
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  getParticularUser = async (queryName, fieldname) => {
    console.log(queryName, fieldname);
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/getParticularUser",
      {
        queryName: queryName,
        fieldname: fieldname,
      },
      { withCredentials: false }
    );
  };

  getUserById = async (id) => {
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/getUserById",
      {
        id: id,
      },
      { withCredentials: false }
    );
  };

  manuallyVerifyUser = async (id, verifiedStatus) => {
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/manuallyVerifyUser",
      {
        id,
        verifiedStatus,
      },
      { withCredentials: false }
    );
  };

  setAccounStatus = async (id, verifiedStatus) => {
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/setAccounStatus",
      {
        id,
        verifiedStatus,
      },
      { withCredentials: false }
    );
  };

  updateFieldForUser = async (id, field, value) => {
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/updateFieldForUser",
      {
        id,
        field,
        value,
      },
      { withCredentials: false }
    );
  };

  deleteUser = async (id) => {
    return axios.post(
      "http://pacanele-gratis-api.alevla.com/deleteUser",
      {
        id: id,
      },
      { withCredentials: false }
    );
  };
}

export default new UsersDataService();
