import { configureStore } from '@reduxjs/toolkit';
import addPromoItemReducer from './features/promoItems/addPromoItemSlice';
import checkCurrentUserReducer from './features/checkCurrentUserSlice';
import rememberPromoCarouselItems from './features/promoItems/rememberPromoCarouselItemsSlice';
import rememberCasinoItems from './features/casionoItems/rememberCasinoItems';
import rememberGameItems from './features/gamesItems/rememberGameItemsSlice';
import addOrEditGameItemSlice from './features/gamesItems/addOrEditGameItemSlice';
import addOrEditCasinoItems from './features/casionoItems/addOrEditCasinoItemsSlice';
import addOrEditInAppGamesItemsSlice from './features/inAppGamesItems/addOrEditInAppGamesItems.js';
import rememberStoreItemsSlice from './features/storeItems/rememberStoreItems';
import addOrEditStoreItems from './features/storeItems/addOrEditStoreItems';
import rememberOrderItemsSlice from './features/orderItems/rememberOrderItemsSlice';

export const store = configureStore({
  reducer: {
    rememberPromoCarouselItems: rememberPromoCarouselItems,
    addOrEditCasinoItems: addOrEditCasinoItems,
    rememberCasinoItems: rememberCasinoItems,
    addPromoItem: addPromoItemReducer,
    checkCurrentUser: checkCurrentUserReducer,
    rememberGameItems: rememberGameItems,
    addOrEditGameItem: addOrEditGameItemSlice,
    addOrEditInAppGamesItems: addOrEditInAppGamesItemsSlice,
    rememberStoreItems: rememberStoreItemsSlice,
    addOrEditStoreItems: addOrEditStoreItems,
    rememberOrderItems: rememberOrderItemsSlice,
  },
});
