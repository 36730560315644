import React, { useState, useEffect } from "react";
import {
  Heading,
  Container,
  Box,
  Button,
  FormControl,
  Center,
  Input,
  FormLabel,
  FormErrorMessage,
  HStack,
  Spacer,
  Text,
  TableContainer,
  Tbody,
  Tr,
  Td,
  Table,
  Flex,
  IconButton,
  Grid,
  GridItem,
  Thead,
  Th,
  Tooltip,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  useEditableControls,
  ButtonGroup,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import _ from "underscore";
import { Formik, Form, Field, useFormikContext } from "formik";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate, useParams } from "react-router-dom";
import EmailSchema from "./EmailSchema";
import PointsSchema from "./PointsSchema";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsersDataService from "../../services/users-services";
import DeleteModal from "../../components/Modal/Modal";
import {
  SearchIcon,
  CheckCircleIcon,
  NotAllowedIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
} from "@chakra-ui/icons";
import AliasSchema from "./AliasSchema";
import IDSchema from "./IDSchema";
import { FaSnowflake, FaSun } from "react-icons/fa";

function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
      <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
    </Flex>
  );
}

function Utilizatori() {
  const [isLoading, setIsLoading] = useState(false);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const [submit2IsLoading, setSubmit2IsLoading] = useState(false);
  const [userFullData, setUserFullData] = useState({});
  const [invitedFriendsArray, setInvitedFriendsArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentAccount, setCurrentAccount] = useState(-1);
  const [userAccounts, setUserAccounts] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      submitForm({ id: params.id });
      setActiveTab(1);
    }
  }, []);

  const getAccountData = async (accountNo, userAccounts) => {
    let tmpEmails = [];

    setErrorMessage("");
    setInvitedFriendsArray([]);
    setUserFullData({});

    console.log("getAccountData", userAccounts[accountNo]);

    if (Object.keys(userAccounts[accountNo]).length > 0) {
      if (userAccounts[accountNo].invitedUsers !== undefined) {
        for (let id of userAccounts[accountNo].invitedUsers) {
          let auxEmail = await UsersDataService.getUserById(id);
          if (auxEmail.data() !== undefined)
            tmpEmails.push(auxEmail.data().email);
        }
      }

      setUserFullData(userAccounts[accountNo]);

      setInvitedFriendsArray(tmpEmails);
    } else {
      setErrorMessage(
        "Aceasta adresa de mail nu este asociata niciunui utilizator."
      );
    }
  };

  const submitForm = async (values, resetForm) => {
    console.log(values);
    setUserAccounts([]);
    setSubmitIsLoading(true);
    setCurrentAccount(-1);
    setUserFullData({});

    let userFullDataAux = [];

    if (values.id !== undefined) {
      let user = await UsersDataService.getUserById(values.id);
      user = user.data;
      console.log(user);

      let newUser = {
        ...user,
        id: values.id,
      };

      userFullDataAux.push(newUser);
    } else {
      userFullDataAux = await UsersDataService.getParticularUser(
        values.email !== undefined ? values.email : values.alias,
        values.email !== undefined ? "email" : "alias"
      );
      userFullDataAux = userFullDataAux.data;
    }

    if (userFullDataAux.length > 0) {
      setUserAccounts(userFullDataAux);
      setCurrentAccount(1);
      await getAccountData(0, userFullDataAux);
      if (!params.id) resetForm({});
    } else {
      setErrorMessage("Nu exista un utilizator cu acest mail.");
    }
    setSubmitIsLoading(false);
  };

  const checkUserIdentity = async () => {
    const id = userFullData.id;
    console.log("checkUserIdentity", id, userFullData.manuallyVerified);

    const verifiedStatus =
      userFullData.manuallyVerified === true ? true : false;
    const bonusValue = userFullData.manuallyVerified === true ? -100 : 100;
    try {
      setIsLoading(true);
      await UsersDataService.manuallyVerifyUser(id, !verifiedStatus);
      setUserFullData({
        ...userFullData,
        manuallyVerified: !verifiedStatus,
        coinCount: userFullData.coinCount + parseInt(bonusValue),
      });
      let accounts = [...userAccounts];
      let account = { ...userAccounts[currentAccount - 1] };
      account.manuallyVerified = !verifiedStatus;
      accounts[currentAccount - 1] = account;
      setUserAccounts(accounts);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const setAccounStatus = async () => {
    const id = userFullData.id;
    console.log("userFullData", id, userFullData.frozenAccount);

    const verifiedStatus = userFullData.frozenAccount === true ? true : false;
    try {
      setIsLoading(true);

      await UsersDataService.setAccounStatus(id, !verifiedStatus);
      setUserFullData({
        ...userFullData,
        frozenAccount: !verifiedStatus,
      });
      let accounts = [...userAccounts];
      let account = { ...userAccounts[currentAccount - 1] };
      account.frozenAccount = !verifiedStatus;
      accounts[currentAccount - 1] = account;
      setUserAccounts(accounts);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteUserAccount = async (id) => {
    try {
      await UsersDataService.deleteUser(id);
      setErrorMessage("");
      setInvitedFriendsArray([]);
      setUserFullData({});

      toast.success("Operatiunea s-a realizat cu succes!");
    } catch (e) {
      console.log(e);
      toast.error(`S-a produs urmatoarea eroare: ${e}`);
    }
  };

  const submitAddPointsForm = async (values, resetForm) => {
    try {
      setSubmit2IsLoading(true);
      const id = userFullData.id;
      resetForm({});
      setSubmit2IsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Container mt="3rem" minWidth="95%">
        <Heading size="xl"> Cauta Detalii despre un Utilizator</Heading>
        <HStack mt="3">
          <Link to="/admin-dashboard">
            <Button
              leftIcon={<HiArrowNarrowLeft />}
              colorScheme="green"
              variant="outline"
            >
              Dashboard
            </Button>
          </Link>
          <Spacer />
        </HStack>
      </Container>
      <Container mt="3rem" maxW="container.lg">
        <Box
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="sm"
          my="2rem"
          p="2rem"
          maxW={"900px"}
        >
          <Box p={8}>
            <Tabs variant="enclosed" defaultIndex={activeTab}>
              <TabList>
                <Tab>
                  <Text>Cauta dupa ID</Text>
                </Tab>
                <Tab>
                  <Text>Cauta dupa email</Text>
                </Tab>
                <Tab>
                  <Text>Cauta dupa alias</Text>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Formik
                    initialValues={{
                      id: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      submitForm(values, resetForm);
                    }}
                    validationSchema={IDSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      dirty,
                      isValid,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <FormControl
                            isInvalid={errors.id && touched.id}
                            mb={8}
                            variant="floating"
                            isRequired
                          >
                            <Field
                              name="id"
                              as={Input}
                              value={values.id}
                              onChange={(e) => {
                                setFieldValue("id", e.target.value);
                              }}
                              placeholder=" "
                            ></Field>
                            <FormLabel>ID utilizator</FormLabel>
                            {errors.id && touched.id ? (
                              <FormErrorMessage>{errors.id}</FormErrorMessage>
                            ) : null}
                          </FormControl>

                          <Center mt={"2rem"}>
                            <Button
                              isDisabled={!(dirty && isValid)}
                              isLoading={submitIsLoading}
                              minW={"200px"}
                              loadingText="Se actualizeaza"
                              type="submit"
                              colorScheme="green"
                              width="full"
                              textAlign="center"
                            >
                              Cauta utilizator
                            </Button>
                          </Center>
                        </Form>
                      );
                    }}
                  </Formik>
                </TabPanel>
                <TabPanel>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      submitForm(values, resetForm);
                    }}
                    validationSchema={EmailSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      dirty,
                      isValid,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <FormControl
                            isInvalid={errors.email && touched.email}
                            mb={8}
                            variant="floating"
                            isRequired
                          >
                            <Field
                              name="email"
                              as={Input}
                              value={values.email}
                              onChange={(e) => {
                                setFieldValue("email", e.target.value);
                              }}
                              placeholder=" "
                            ></Field>
                            <FormLabel>Email utilizator</FormLabel>
                            {errors.email && touched.email ? (
                              <FormErrorMessage>
                                {errors.email}
                              </FormErrorMessage>
                            ) : null}
                          </FormControl>

                          <Center mt={"2rem"}>
                            <Button
                              isDisabled={!(dirty && isValid)}
                              isLoading={submitIsLoading}
                              minW={"200px"}
                              loadingText="Se actualizeaza"
                              type="submit"
                              colorScheme="green"
                              width="full"
                              textAlign="center"
                            >
                              Cauta utilizator
                            </Button>
                          </Center>
                        </Form>
                      );
                    }}
                  </Formik>
                </TabPanel>
                <TabPanel>
                  <Formik
                    initialValues={{
                      alias: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      submitForm(values, resetForm);
                    }}
                    validationSchema={AliasSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      dirty,
                      isValid,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <FormControl
                            isInvalid={errors.alias && touched.alias}
                            mb={8}
                            variant="floating"
                            isRequired
                          >
                            <Field
                              name="alias"
                              as={Input}
                              value={values.alias}
                              onChange={(e) => {
                                setFieldValue("alias", e.target.value);
                              }}
                              placeholder=" "
                            ></Field>
                            <FormLabel>Alias utilizator</FormLabel>
                            {errors.alias && touched.alias ? (
                              <FormErrorMessage>
                                {errors.alias}
                              </FormErrorMessage>
                            ) : null}
                          </FormControl>

                          <Center mt={"2rem"}>
                            <Button
                              isDisabled={!(dirty && isValid)}
                              isLoading={submitIsLoading}
                              minW={"200px"}
                              loadingText="Se actualizeaza"
                              type="submit"
                              colorScheme="green"
                              width="full"
                              textAlign="center"
                            >
                              Cauta utilizator
                            </Button>
                          </Center>
                        </Form>
                      );
                    }}
                  </Formik>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <Heading mt={8} color="red" size="sm">
              {errorMessage}
            </Heading>
          </Box>
          <Box my={3} />

          <Box my={3} />

          {showSpinner && <Spinner />}

          {Object.keys(userFullData).length > 0 && (
            <>
              <Heading size="lg">Detalii user {userFullData.alias}</Heading>{" "}
              <Heading size="sm">
                Exista {userAccounts.length}{" "}
                {userAccounts.length === 1
                  ? "cont asociat"
                  : "conturi asociate"}{" "}
                cu acest email.
              </Heading>{" "}
              <Box my={3} />
              {[...Array(userAccounts.length)].map((e, i) => {
                return (
                  <Button
                    key={`button-${i}`}
                    size="sm"
                    isActive={i + 1 === currentAccount ? true : false}
                    onClick={async () => {
                      return (
                        setCurrentAccount(i + 1),
                        setShowSpinner(true),
                        await getAccountData(i, userAccounts),
                        setShowSpinner(false)
                      );
                    }}
                  >
                    Cont {i + 1}
                  </Button>
                );
              })}
              <TableContainer py={8}>
                <Table size="sm" colorScheme="blackAlpha" variant="striped">
                  <Tbody>
                    <Tr>
                      <Td>
                        <b>ID:</b>{" "}
                      </Td>
                      <Td>{userFullData.id}</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Email:</b>{" "}
                      </Td>

                      <Td>
                        <Editable
                          textAlign="center"
                          isPreviewFocusable={false}
                          defaultValue={userFullData.email}
                          onSubmit={async (value) =>
                            await UsersDataService.updateFieldForUser(
                              userFullData.id,
                              "email",
                              value
                            )
                          }
                        >
                          <HStack>
                            <EditablePreview />
                            {/* Here is the custom input */}
                            <Input as={EditableInput} />
                            <Spacer />
                            <EditableControls />
                          </HStack>
                        </Editable>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Alias:</b>{" "}
                      </Td>{" "}
                      <Td>
                        {" "}
                        <Editable
                          textAlign="center"
                          isPreviewFocusable={false}
                          defaultValue={userFullData.alias}
                         
                          onSubmit={async (value) =>
                            await UsersDataService.updateFieldForUser(
                              userFullData.id,
                              "alias",
                              value
                            )
                          }
                        >
                          <HStack>
                            <EditablePreview />
                            {/* Here is the custom input */}
                            <Input as={EditableInput} />
                            <Spacer />
                            <EditableControls />
                          </HStack>
                        </Editable>
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <b>Prenume:</b>{" "}
                      </Td>{" "}
                      <Td>
                        {" "}
                        <Editable
                          textAlign="center"
                          isPreviewFocusable={false}
                          defaultValue={userFullData.firstName}
                         
                          onSubmit={async (value) =>
                            await UsersDataService.updateFieldForUser(
                              userFullData.id,
                              "firstName",
                              value
                            )
                          }
                        >
                          <HStack>
                            <EditablePreview />
                            {/* Here is the custom input */}
                            <Input as={EditableInput} />
                            <Spacer />
                            <EditableControls />
                          </HStack>
                        </Editable>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Nume:</b>{" "}
                      </Td>
                      <Td>
                        <Editable
                          textAlign="center"
                          isPreviewFocusable={false}
                          defaultValue={userFullData.lastName}
                          onSubmit={async (value) =>
                            await UsersDataService.updateFieldForUser(
                              userFullData.id,
                              "lastName",
                              value
                            )
                          }
                        >
                          <HStack>
                            <EditablePreview />
                            {/* Here is the custom input */}
                            <Input as={EditableInput} />
                            <Spacer />
                            <EditableControls />
                          </HStack>
                        </Editable>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        {" "}
                        <b>Tel:</b>{" "}
                      </Td>
                      <Td>
                        <Editable
                          textAlign="center"
                          isPreviewFocusable={false}
                          defaultValue={userFullData.phoneNumber}
                          onSubmit={async (value) =>
                            await UsersDataService.updateFieldForUser(
                              userFullData.id,
                              "phoneNumber",
                              value
                            )
                          }
                        >
                          <HStack>
                            <EditablePreview />
                            {/* Here is the custom input */}
                            <Input as={EditableInput} />
                            <Spacer />
                            <EditableControls />
                          </HStack>
                        </Editable>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        {" "}
                        <b>User verificat manual</b>{" "}
                      </Td>
                      <Td>
                        {userFullData.manuallyVerified !== undefined ? (
                          userFullData.manuallyVerified === true ? (
                            <HStack>
                              <Text color={"green"}>DA</Text>
                              <Spacer />
                              <Tooltip label="Invalidează identitatea">
                                <IconButton
                                  isLoading={isLoading}
                                  size="sm"
                                  colorScheme="green"
                                  aria-label="Invalidează identitatea"
                                  onClick={checkUserIdentity}
                                  icon={<NotAllowedIcon />}
                                />
                              </Tooltip>
                            </HStack>
                          ) : (
                            <HStack>
                              <Text color={"red"}>NU</Text>
                              <Spacer />
                              <Tooltip label="Validează identitatea">
                                <IconButton
                                  isLoading={isLoading}
                                  size="sm"
                                  colorScheme="green"
                                  aria-label="Validează identitatea"
                                  onClick={checkUserIdentity}
                                  icon={<CheckCircleIcon />}
                                />
                              </Tooltip>
                            </HStack>
                          )
                        ) : (
                          <HStack>
                            {" "}
                            <Text color={"red"}>NU</Text>
                            <Spacer />
                            <Tooltip label="Validează identitatea">
                              <IconButton
                                isLoading={isLoading}
                                size="sm"
                                aria-label="Validează identitatea"
                                colorScheme="green"
                                onClick={checkUserIdentity}
                                icon={<CheckCircleIcon />}
                              />
                            </Tooltip>
                          </HStack>
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        {" "}
                        <b>Cont inghetat</b>{" "}
                      </Td>
                      <Td>
                        {userFullData.frozenAccount !== undefined ? (
                          userFullData.frozenAccount === true ? (
                            <HStack>
                              <Text color={"red"}>DA</Text>
                              <Spacer />
                              <Tooltip label="Dezgheata contul">
                                <IconButton
                                  isLoading={isLoading}
                                  size="sm"
                                  colorScheme="green"
                                  aria-label="Dezgheata contul"
                                  onClick={setAccounStatus}
                                  icon={<FaSun />}
                                />
                              </Tooltip>
                            </HStack>
                          ) : (
                            <HStack>
                              <Text color={"green"}>NU</Text>
                              <Spacer />
                              <Tooltip label="Ingheata contul">
                                <IconButton
                                  isLoading={isLoading}
                                  size="sm"
                                  colorScheme="green"
                                  aria-label="Ingheata contul"
                                  onClick={setAccounStatus}
                                  icon={<FaSnowflake />}
                                />
                              </Tooltip>
                            </HStack>
                          )
                        ) : (
                          <HStack>
                            {" "}
                            <Text color={"green"}>NU</Text>
                            <Spacer />
                            <Tooltip label="Ingheata contul">
                              <IconButton
                                isLoading={isLoading}
                                size="sm"
                                aria-label="Ingheata contul"
                                colorScheme="green"
                                onClick={setAccounStatus}
                                icon={<FaSnowflake />}
                              />
                            </Tooltip>
                          </HStack>
                        )}
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <b>Notificari Personale:</b>{" "}
                      </Td>
                      <Td>
                        {" "}
                        {userFullData.personalNotificationsIsEnabled ? (
                          <Text color={"green"}>DA</Text>
                        ) : (
                          <Text color={"green"}>NU</Text>
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        {" "}
                        <b>Notificari Generale:</b>{" "}
                      </Td>
                      <Td>
                        {userFullData.generalNotificationsIsEnabled ? (
                          <Text color={"green"}>DA</Text>
                        ) : (
                          <Text color={"green"}>NU</Text>
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Notificari Evenimente:</b>{" "}
                      </Td>
                      <Td>
                        {userFullData.eventsNotificationsIsEnabled ? (
                          <Text color={"green"}>DA</Text>
                        ) : (
                          <Text color={"green"}>NU</Text>
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Aprobare Marketing:</b>{" "}
                      </Td>
                      <Td>
                        {userFullData.marketingApproval ? (
                          <Text color={"green"}>DA</Text>
                        ) : (
                          <Text color={"green"}>NU</Text>
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Notițe:</b>{" "}
                      </Td>
                      <Td>
                        <Editable
                          textAlign="center"
                          isPreviewFocusable={false}
                          defaultValue={userFullData.notes}
                          onSubmit={async (value) =>
                            await UsersDataService.updateFieldForUser(
                              userFullData.id,
                              "notes",
                              value
                            )
                          }
                        >
                          <HStack>
                            <EditablePreview />
                            {/* Here is the custom input */}
                            <Input as={EditableTextarea} />
                            <Spacer />
                            <EditableControls />
                          </HStack>
                        </Editable>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <Center>
                <DeleteModal
                  deleteFunction={deleteUserAccount}
                  id={userFullData.id}
                  explanation={"cont"}
                />
              </Center>
            </>
          )}
        </Box>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <br />
      </Container>
    </>
  );
}

export default Utilizatori;
