import {
  Box,
  Flex,
  Heading,
  useColorModeValue,
  Spacer,
  Image,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  VStack,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { auth } from '../../services/firebase-admin';
import UsersDataService from '../../services/users-services';
import CasionosDataService from '../../services/casinos-services';
import GamesDataService from '../../services/games-services';
import PromoCarouselDataService from '../../services/promo-carousel-services';
import ModalConfirmation from '../ModalConfirmation/ModalConfirmation';
import { setCurrentUser } from '../../features/checkCurrentUserSlice';

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { GiHamburgerMenu } from 'react-icons/gi';
import {
  DownloadIcon,
  ExternalLinkIcon,
  HamburgerIcon,
} from '@chakra-ui/icons';

export const Navbar = props => {
  const [isExporting, setIsExporting] = useState(false);
  const isLoggedIn = useSelector(state => state.checkCurrentUser.isLoggedIn);
  const dispatch = useDispatch();

  const { height, width } = useWindowDimensions();

  const getUsersAndDownload = async () => {
    setIsExporting(true);

    // Downloading users
    const userJson = await UsersDataService.getAllUsers();

    let jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(userJson)
    )}`;
    let link = document.createElement('a');
    link.href = jsonString;
    let today = new Date();
    let time = today.toLocaleDateString('ro-RO');
    link.download = time + '_utilizatori_app.json';
    link.click();

    // Downloading games
    const gamesJson = await GamesDataService.getAllGames();
    jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(gamesJson)
    )}`;
    link = document.createElement('a');
    link.href = jsonString;
    link.download = time + '_jocuri_app.json';
    link.click();

    // Downloading bonuses
    const bonusesJson = await CasionosDataService.getAllCasinos();
    jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(bonusesJson)
    )}`;
    link = document.createElement('a');
    link.href = jsonString;
    link.download = time + '_bonusuri_app.json';
    link.click();

    // Downloading the promo carousel
    const promoCarouselJson =
      await PromoCarouselDataService.getAllPromoCarousels();
    jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(promoCarouselJson)
    )}`;
    link = document.createElement('a');
    link.href = jsonString;
    link.download = time + '_promo_carousel_app.json';
    link.click();

    setIsExporting(false);
  };

  return (
    <Flex
      w="100%"
      minWidth="100%"
      alignItems="center"
      gap="2"
      py="1rem"
      pr="3rem"
      boxShadow={useColorModeValue('sm', 'sm-dark')}
      sx={{
        position: '-webkit-sticky',
        /* Safari */ position: 'sticky',
        top: '0',
        zIndex: 99,
        backdropFilter: 'blur(20px)',
      }}
      bg="whiteAlpha.200"
    >
      <Link to="/admin-dashboard">
        <Box py="2" px="4">
          <Image
            ml="40px"
            boxSize="65px"
            objectFit="cover"
            src="https://cazino365.ro/wp-content/uploads/2022/08/cropped-favicon-270x270.png"
            alt="Cazino 365"
          />
        </Box>
      </Link>

      <Heading size={width < 500 ? 'sm' : 'md'}>Centru de Comanda</Heading>
      {/* <Link to="/jibberish-work">
        <Box py="2" px="4">
          <Text>Jibberish</Text>
        </Box>
      </Link> */}
      <Spacer />

      {isLoggedIn && (
        <>
          {/* <Button
            isLoading={isExporting}
            onClick={getUsersAndDownload}
            colorScheme={'purple'}
          >
            Exporta DB
          </Button>{' '} */}
          {width > 800 ? (
            <>
              <ModalConfirmation
                isLoading={isExporting}
                confirmFunction={getUsersAndDownload}
              />{' '}
              <Button
                ml="3"
                onClick={() => {
                  console.log('clicked');
                  auth()
                    .signOut()
                    .then(function () {
                      dispatch(
                        setCurrentUser({
                          userId: '',
                          isAdmin: false,
                          isLoggedIn: false,
                        })
                      );
                    })
                    .catch(function (error) {
                      console.log('Error');
                    });
                }}
                colorScheme={'red'}
              >
                Sign Out
              </Button>{' '}
            </>
          ) : (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon />}
                variant="outline"
              />
              <MenuList>
                {/* <MenuItem icon={<DownloadIcon />}>
                  
                </MenuItem> */}
                <VStack>
                  <ModalConfirmation
                    isLoading={isExporting}
                    confirmFunction={getUsersAndDownload}
                  />{' '}
                  <Button
                    onClick={() => {
                      console.log('clicked');
                      auth()
                        .signOut()
                        .then(function () {
                          dispatch(
                            setCurrentUser({
                              userId: '',
                              isAdmin: false,
                              isLoggedIn: false,
                            })
                          );
                        })
                        .catch(function (error) {
                          console.log('Error');
                        });
                    }}
                    variant="ghost"
                  >
                    Sign Out
                  </Button>
                </VStack>
              </MenuList>
            </Menu>
          )}
        </>
      )}
    </Flex>
  );
};
