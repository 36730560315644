import React from "react";
import { useEffect, useState } from "react";
import GamesDataService from "../../services/games-services";
import { useSelector, useDispatch } from "react-redux";
import { setGameItems } from "../../features/gamesItems/rememberGameItemsSlice";
import { clearState } from "../../features/gamesItems/addOrEditGameItemSlice";

import {
  Heading,
  Container,
  Box,
  HStack,
  Spacer,
  SimpleGrid,
  Button,
  Spinner,
  Center,
} from "@chakra-ui/react";

import { HiArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";

function GamesItems(props) {
  const dispatch = useDispatch();

  const gameCollection = useSelector((state) => state.rememberGameItems);
  const [isLoading, setIsLoading] = useState(false);

  const getGameItems = async () => {
    console.log("Getting items...");
    try {
      const tmp = await GamesDataService.getAllGames();

      for (let x in tmp.data) {
        console.log(tmp.data[x]);
        dispatch(
          setGameItems({
            id: tmp.data[x].id,
            title: tmp.data[x].title,
            priority: tmp.data[x].priority,
            isVisible: tmp.data[x].isVisible,
            icon: tmp.data[x].icon,
            games: tmp.data[x].games,
          })
        );
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (Object.keys(gameCollection["fruits"].games).length === 0) {
      setIsLoading(true);
      getGameItems();
      console.log("Getting items from remote...");
    } else {
      console.log("Getting items from store...");
    }
  }, []);

  return (
    <>
      <Container mt="3rem" minWidth="95%">
        <Heading size="xl">Games Dashboard</Heading>
        <HStack mt="3">
          <Link to="/admin-dashboard">
            <Button
              leftIcon={<HiArrowNarrowLeft />}
              colorScheme="green"
              variant="outline"
            >
              Dashboard
            </Button>
          </Link>
          <Spacer />
          <Link to="/add-game-item">
            <Button
              onClick={() => {
                dispatch(clearState());
              }}
              colorScheme={"green"}
            >
              Adauga Joc
            </Button>
          </Link>
        </HStack>
        <Heading mt={10} size="lg">
          Categorii jocuri
        </Heading>

        {isLoading ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          <SimpleGrid
            mt="10"
            spacing={8}
            columns={{ sm: 1, md: 2, lg: 3, xl: 4 }}
          >
            {Object.keys(gameCollection).map((gameCategory, index) => {
              return (
                <Link
                  key={gameCategory}
                  width="100%"
                  to={`${gameCollection[gameCategory].id}`}
                >
                  <Box p={5} shadow="md" borderWidth="1px">
                    {" "}
                    <Center>
                      <Heading size="md">
                        {gameCollection[gameCategory].name}
                      </Heading>
                    </Center>
                  </Box>
                </Link>
              );
            })}
          </SimpleGrid>
        )}
      </Container>
    </>
  );
}

export default GamesItems;
