import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Spacer,
} from "@chakra-ui/react";
import * as React from "react";
import { PasswordField } from "../PasswordField/PasswordField";
import { useState } from "react";
import { auth, signIn } from "../../services/firebase-admin";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentUser } from "../../features/checkCurrentUserSlice";

export const Main = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleAction = async () => {
    const authentication = auth();
    if (email !== "" && password !== "") {
      try {
        const { user } = await signIn(authentication, email, password);
        console.log(user);
        sessionStorage.setItem("Auth Token", user.accessToken);
        dispatch(
          setCurrentUser({ userId: user.uid, isAdmin: true, isLoggedIn: true })
        );

        navigate("/admin-dashboard");
      } catch (error) {
        if (error.code === "auth/wrong-password") {
          toast.error("Parola este gresita");
        }
        if (error.code === "auth/user-not-found") {
          toast.error("Adresa de email nu este corecta");
        }
      }
    }
  };

  return (
    <Container maxW="lg" py="24" px="8">
      <Stack spacing="8">
        <Stack spacing="6" textAlign="center">
          <Heading size="lg">Autentifică-te pentru a continua</Heading>
          <Text>Cazino 365 Admin dashboard</Text>
        </Stack>
        <Box py="8" px="10" boxShadow="xl" borderRadius="md">
          <Stack spacing="6">
            <FormControl isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormControl>
            <PasswordField setPassword={setPassword} value={password} />
            <Spacer />
            <Button onClick={handleAction} colorScheme="green">
              Sign in
            </Button>
            <ToastContainer
              position="bottom-center"
              theme="colored"
              pauseOnHover={false}
              pauseOnFocusLoss={false}
            />
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};
