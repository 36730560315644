import React from "react";
import { useEffect } from "react";
import DeleteModal from "../../../components/Modal/Modal";
import { ToastContainer, toast } from "react-toastify";

import {
  VStack,
  Heading,
  Box,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  HStack,
  Spacer,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Container,
  Center,
  Icon,
} from "@chakra-ui/react";
import { HiArrowNarrowLeft, HiDownload } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  prepareStateForEdit,
  updateState,
  clearState,
} from "../../../features/gamesItems/addOrEditGameItemSlice";
import { removeGameItem } from "../../../features/gamesItems/rememberGameItemsSlice";
import GamesDataService from "../../../services/games-services";
import { MdSettings } from "react-icons/md";

function GamesAccordion(props) {
  const dispatch = useDispatch();
  const gameCollection = useSelector((state) => state.rememberGameItems);

  const deleteButtonClicked = async (id) => {
    console.log(id);
    try {
      let gameId = id.split(".")[0];
      let category = id.split(".")[1];
      await GamesDataService.removeGameItem(
        category,
        gameId,
        gameCollection[category].games,
        gameCollection[category].isVisible,
        gameCollection[category].priority,
        gameCollection[category].icon,
        gameCollection[category].title
      );
      dispatch(removeGameItem({ id: gameId, category: category }));
      toast.success("Operatiunea s-a realizat cu succes!");
    } catch (error) {
      console.log(error);
      toast.error("S-a produs o eroare. Incearca din nou.");
    }
  };

  const editButtonClicked = (e) => {
    const id = e.target.id;
    const category = id.split(".")[1];
    const gameId = id.split(".")[0];

    dispatch(
      prepareStateForEdit({
        ...gameCollection[category].games[gameId],
        category: category,
        id: gameId,
      })
    );
  };

  const categoryEditButtonClicked = (e) => {
    const category = e.target.id;
    console.log(category);
    dispatch(
      prepareStateForEdit({
        category: category,
        title: gameCollection[params.category].title,
        icon: gameCollection[params.category].icon,
        isVisible: gameCollection[params.category].isVisible,
        priority: gameCollection[params.category].priority,
      })
    );
  };

  const params = useParams();

  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(gameCollection[params.category].games)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${params.category}.json`;

    link.click();
  };

  return (
    <>
      <Container mt="3rem" mb={20} minWidth="95%">
        <Heading size="xl">Games Dashboard</Heading>
        <HStack mt="3">
          <Link to="/games-items">
            <Button
              leftIcon={<HiArrowNarrowLeft />}
              colorScheme="green"
              variant="outline"
            >
              Categorii Jocuri
            </Button>
          </Link>
          <Button
            leftIcon={<HiDownload />}
            colorScheme="purple"
            onClick={() => {
              exportData();
            }}
          >
            Descarca jocurile
          </Button>
          <Spacer />
          <Link to="/add-game-item">
            <Button
              onClick={() => {
                dispatch(clearState());
                dispatch(
                  updateState({
                    value: params.category,
                    name: "category",
                  })
                );
              }}
              colorScheme={"green"}
            >
              Adauga Joc
            </Button>
          </Link>
        </HStack>
        <Box p={5} my={5} borderWidth="1px">
          <HStack>
            <Heading size="md">Setări categorie</Heading>
            <Spacer />
            <Link to="/edit-game-category-meta">
              <Button
                size="sm"
                colorScheme="gray"
                variant="solid"
                id={`${params.category}`}
                onClick={(e) => categoryEditButtonClicked(e)}
              >
                Editeaza Categoria
              </Button>
            </Link>
          </HStack>
          <TableContainer>
            <Table size="md" variant="unstyled">
              <Tbody>
                <Tr>
                  <Td>
                    <Heading size="sm">Titlu:</Heading>
                  </Td>
                  <Td sx={{ whiteSpace: "normal" }}>
                    <Heading size="sm">
                      {gameCollection[params.category].title}
                    </Heading>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Heading size="sm">Prioritate:</Heading>
                  </Td>
                  <Td sx={{ whiteSpace: "normal" }}>
                    <Heading size="sm">
                      {gameCollection[params.category].priority}
                    </Heading>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Heading size="sm">Vizibilitate:</Heading>
                  </Td>
                  <Td sx={{ whiteSpace: "normal" }}>
                    <Heading size="sm">
                      {gameCollection[params.category].isVisible ? "DA" : "NU"}
                    </Heading>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Heading size="sm">Icoana:</Heading>
                  </Td>
                  <Td sx={{ whiteSpace: "normal" }}>
                    <Heading size="sm">
                      {gameCollection[params.category].icon}
                    </Heading>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Heading mt={10} mb={5} size="lg">
          {gameCollection[params.category].name}
        </Heading>

        <Accordion allowToggle>
          {Object.keys(gameCollection[params.category].games)
            .sort()
            .map((gameKey, index) => {
              return (
                <AccordionItem key={gameKey}>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <HStack>
                        <Box ml={"20px"}></Box>
                        <Heading size="sm">
                          {gameKey}
                          {" - "}
                          {gameCollection[params.category].games[gameKey].name}
                        </Heading>
                      </HStack>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Box
                      key={gameCollection[params.category].games[gameKey].name}
                      borderWidth="1px"
                      borderRadius="sm"
                      // boxShadow="sm"
                      p="1rem"
                    >
                      <VStack tspacing={4} align="stretch">
                        <HStack as={"span"}>
                          <Link to="/edit-game-item">
                            <Button
                              size="sm"
                              colorScheme="teal"
                              variant="solid"
                              id={`${gameKey}.${params.category}`}
                              onClick={(e) => editButtonClicked(e)}
                            >
                              Editeaza
                            </Button>
                          </Link>
                          <Spacer />
                          <DeleteModal
                            deleteFunction={deleteButtonClicked}
                            id={`${gameKey}.${params.category}`}
                          />
                        </HStack>
                        <TableContainer>
                          <Table size="md" variant="unstyled">
                            <Tbody>
                              <Tr>
                                <Td>
                                  <Heading size="sm">Nume:</Heading>
                                </Td>
                                <Td sx={{ whiteSpace: "normal" }}>
                                  <Heading size="sm">
                                    {
                                      gameCollection[params.category].games[
                                        gameKey
                                      ].name
                                    }
                                  </Heading>
                                </Td>
                              </Tr>

                              <Tr>
                                <Td>
                                  <Text fontSize="sm">URL Imagine</Text>
                                </Td>
                                <Td sx={{ whiteSpace: "normal" }}>
                                  <Text fontSize="sm">
                                    {
                                      gameCollection[params.category].games[
                                        gameKey
                                      ].imageURL
                                    }
                                  </Text>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td>
                                  <Text fontSize="sm">URL Web</Text>
                                </Td>
                                <Td sx={{ whiteSpace: "normal" }}>
                                  <Text fontSize="sm">
                                    {
                                      gameCollection[params.category].games[
                                        gameKey
                                      ].webURL
                                    }
                                  </Text>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td>
                                  <Text fontSize="sm">URL Iframe</Text>
                                </Td>
                                <Td sx={{ whiteSpace: "normal" }}>
                                  <Text fontSize="sm">
                                    {
                                      gameCollection[params.category].games[
                                        gameKey
                                      ].iframeURL
                                    }
                                  </Text>
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </VStack>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
        </Accordion>
      </Container>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default GamesAccordion;
