import React from "react";
import DeleteModal from "../../../components/Modal/Modal";
import {
  VStack,
  Heading,
  Box,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  HStack,
  Spacer,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import CasinosDataService from "../../../services/casinos-services";
import { removeItemFromCazino } from "../../../features/casionoItems/rememberCasinoItems";
import { prepareStateForEdit } from "../../../features/casionoItems/addOrEditCasinoItemsSlice";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

function CasinoAccordion(props) {
  const dispatch = useDispatch();

  const generalState = useSelector((state) => state.rememberCasinoItems);

  const deleteButtonClicked = async (id) => {
    try {
      let index = id.split("_")[0];
      let casino_id = id.split("_")[1];
      console.log(generalState[casino_id].cazinos[index]);
      // firebase needs the complete element
      await CasinosDataService.deleteCazino(
        generalState[casino_id].cazinos[index],
        casino_id
      );
      dispatch(removeItemFromCazino({ index: index, id: casino_id }));
      toast.success("Operatiunea s-a realizat cu succes!");
    } catch (error) {
      console.log(error);
      toast.error("S-a produs o eroare. Incearca din nou.");
    }
  };

  const editButtonClicked = (e) => {
    const id = e.target.id;
    let index = id.split("-")[0];
    let casino_id = id.split("-")[1];
    console.log(generalState[casino_id].cazinos[index]);
    dispatch(
      prepareStateForEdit({
        ...generalState[casino_id].cazinos[index],
        id: casino_id,
        index: index,
      })
    );
  };

  return (
    <>
      <Accordion allowToggle>
        {props.cazinos.map((doc, index) => {
          return (
            <AccordionItem key={index}>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <HStack>
                    <Box ml={"20px"}></Box>
                    <Heading size="sm">{doc.name}</Heading>
                  </HStack>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Box
                  key={doc.name}
                  borderWidth="1px"
                  borderRadius="sm"
                  // boxShadow="sm"
                  p="1rem"
                >
                  <VStack tspacing={4} align="stretch">
                    <HStack as={"span"}>
                      <Link to="/edit-casino-item">
                        <Button
                          size="sm"
                          colorScheme="teal"
                          variant="solid"
                          id={`${index}-${props.whichIs}`}
                          onClick={(e) => editButtonClicked(e)}
                        >
                          Editeaza
                        </Button>
                      </Link>
                      <Spacer />
                      <DeleteModal
                        deleteFunction={deleteButtonClicked}
                        id={`${index}_${props.whichIs}`}
                      />
                    </HStack>
                    <TableContainer>
                      <Table size="md" variant="unstyled">
                        <Tbody>
                          <Tr>
                            <Td>
                              <Heading size="sm">Nume:</Heading>
                            </Td>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Heading size="sm">{doc.name}</Heading>
                            </Td>
                          </Tr>

                          <Tr>
                            <Td>
                              <Text fontSize="sm">Bunus primar:</Text>
                            </Td>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">{doc.primaryBonus}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">Bonus secundar:</Text>
                            </Td>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">{doc.secondaryBonus}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">Header:</Text>
                            </Td>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">
                                {doc.welcomeBonusHeader}
                              </Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">Disclaimer:</Text>
                            </Td>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">{doc.disclaimer}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">URL Bonus:</Text>
                            </Td>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">{doc.bonusURL}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">URL Logo:</Text>
                            </Td>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">{doc.logoURL}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">URL Review:</Text>
                            </Td>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">{doc.reviewURL}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">Rating:</Text>
                            </Td>
                            <Td sx={{ whiteSpace: "normal" }}>
                              <Text fontSize="sm">{doc.rating}</Text>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </VStack>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default CasinoAccordion;
