import axios from "axios";

class GamesDataService {
  getAllGames = () => {
    return axios.post("https://pacanele-gratis-api.alevla.com/getAllGames");
  };

  addGameItem = (newGame, category, id) => {
    return axios.post(
      "https://pacanele-gratis-api.alevla.com/addGameItem",
      {
        newGame: newGame,
        category: category,
        id: id,
      },
      { withCredentials: false }
    );
  };

  editGameItem = (newGame, category, id) => {
    return axios.post(
      "https://pacanele-gratis-api.alevla.com/addGameItem",
      {
        newGame: newGame,
        category: category,
        id: id,
      },
      { withCredentials: false }
    );
  };

  editGameCategory = (newCategoryMeta, category) => {
    return axios.post(
      "https://pacanele-gratis-api.alevla.com/editGameCategory",
      {
        newCategoryMeta: newCategoryMeta,
        category: category,
      },
      { withCredentials: false }
    );
  };

  removeGameItem = (category, id, games, isVisible, priority, icon, title) => {
    return axios.post(
      "https://pacanele-gratis-api.alevla.com/removeGameItem",
      {
        games: games,
        category: category,
        id: id,
        isVisible: isVisible,
        priority: priority,
        icon: icon,
        title: title,
      },
      { withCredentials: false }
    );
  };
}

export default new GamesDataService();
