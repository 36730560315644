import React from 'react';
import MainteneanceDataService from '../../services/maintenence-services';
import { Button } from '@chakra-ui/react';

function JibberishWork() {
  const handleAction = () => {
    MainteneanceDataService.transferCasinoDataForAllUsers();
  };

  return <Button onClick={handleAction}>Click me</Button>;
}

export default JibberishWork;
