import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const AddOrEditSchema = Yup.object().shape({
  id: Yup.string().required('Id-ul este necesar'),
  category: Yup.string().required('Categoria este necesara'),
  name: Yup.string().required('Numele cazinoului este necesar'),
  imageURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('Logoul cazinoului cazinoului este necesar'),
  iframeURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('URL-ul bonusului este necesar'),
  webURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('URL-ul review-ului este necesar'),
});

export default AddOrEditSchema;
